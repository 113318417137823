import { scssVar } from "../../../../app/scssVar"
import { Fragment } from "react"
import { appName } from "../../../../common/utils"

const Preview = () => {
    return (
        <Fragment>
            <p style={{ fontWeight: 800, marginBottom: 15 }}>
                Aperçu de l'iframe avec ces dimensions
            </p>
            <iframe
                title={appName}
                src={`${window.location.origin}/integration`}
                width="100%"
                height="600px"
                style={{ 
                    borderRadius: "16px",
                    boxShadow: scssVar.shadow2,
                    WebkitBoxShadow: scssVar.shadow2,
                }}
            ></iframe>
        </Fragment>
    )
}

export default Preview