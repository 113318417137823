import logoViolet from "../../common/assets/logo.png"
import logoWhite from "../../common/assets/logo_white.jpg"
import logoCompany from "../../common/assets/logo_company.jpg"
import { Box } from "@mui/material"
import { appCompany, appName } from "../utils"

const Wrapper = ({ children, sx }) => (
    <Box sx={Object.assign({}, {
        width: 90,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }, sx)}>
        {children}
    </Box>
)

const Logo = ({ sx, variant }) => {

    if (variant === "company") {
        return (
            <Wrapper sx={sx?.wrapper}>
                <img
                    src={logoCompany}
                    alt={appCompany}
                    style={Object.assign({}, {
                        width: "100%"
                    }, sx?.image)}
                />
            </Wrapper>
        )
    }

    if (variant === "white") {
        return (
            <Wrapper sx={sx?.wrapper}>
                <img
                    src={logoWhite}
                    alt={appName}
                    style={Object.assign({}, {
                        width: "100%"
                    }, sx?.image)}
                />
            </Wrapper>
        )
    }

    return (
        <Wrapper sx={sx?.wrapper}>
            <img
                src={logoViolet}
                alt={appName}
                style={Object.assign({}, {
                    width: "100%"
                }, sx?.image)}
            />
        </Wrapper>
    )
}

export default Logo