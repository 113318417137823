import { Box, CircularProgress, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { scssVar } from "../../../app/scssVar"
import PageWrapper from "../../../common/components/PageWrapper"
import NotSubForm from "./components/NotSubForm"
import SubForm from "./components/SubForm"
import { Link, useSearchParams } from 'react-router-dom';
import { api } from "../../../common/setupApi"
import { appName } from "../../../common/utils"
import Logo from "../../../common/components/Logo"
import Plan from "./components/Plan"
import SectionTitle from "../components/SectionTitle"

const SpecialSub = () => {
    const [view, setView] = useState("notsub")
    const [searchParams] = useSearchParams();
    const offreId = searchParams.get("offre")
    const [infos, setInfos] = useState("")
    const [status, setStatus] = useState("idle")
    const [error, setError] = useState(null)
    const [priceId, setPriceId] = useState(null)
    const [errorPriceId, setErrorPriceId] = useState(null)

    useEffect(() => {
        const getSub = async () => {
            setStatus("pending")
            setError(null)

            return api
                .get(`v1/stripe/offre-speciale/${offreId}/`)
                .then(res => {
                    setInfos(res.data)
                    setStatus("fulfilled")
                })
                .catch(err => {
                    console.log(err.response)
                    setError("Une erreur est survenue, veuillez réessayer.")
                    setStatus("failed")
                })
        }

        if (offreId) {
            getSub()
        } else {
            setStatus("unauthorized")
        }
    }, [offreId])

    if (status === "idle") {
        return null
    } 

    if (status === "unauthorized") {
        return (
            <Typography sx={{
                color: "black",
                fontFamily: scssVar.fontFamily,
            }}>
                404 Not Found
            </Typography>
        )
    }

    return (
        <PageWrapper>
            <Box sx={{ width: "100%", maxWidth: 600, margin: "0 auto" }}>
                <Box sx={{ m: 4 }} />
                <Link to="/login">
                    <Logo />
                </Link>
                <Typography sx={{
                    mt: 3,
                    mb: 2.5,
                    color: "black",
                    fontFamily: scssVar.fontFamily,
                    fontSize: 35,
                    fontWeight: 700
                }}>
                    Offres spéciales de {appName}
                </Typography>
                {status === "pending" ? (
                    <CircularProgress
                        size={20}
                        sx={{
                            color: scssVar.primary,
                        }}
                    />
                ): status === "failed" ? (
                    <Typography sx={{
                        fontFamily: scssVar.fontFamily,
                        color: scssVar.danger,
                    }}>
                        {error}
                    </Typography>
                ): status === "fulfilled" ? (
                    <Fragment>
                        <SectionTitle
                            n={1} 
                            id="sub_choices"
                            isDanger={errorPriceId}
                        >
                            Choisissez votre offre
                        </SectionTitle>
                        <Box sx={{ m: 2 }} />
                        <Plan
                            selected={priceId}
                            infos={infos} 
                            onSelect={(priceId) => {
                                setPriceId(priceId)
                                const el = document.getElementById("form")
                                el.scrollIntoView({ behavior: "smooth" })
                            }}
                        />
                        <SectionTitle 
                            n={2} 
                            id="form"
                        >
                            {view === "notsub" ? "Inscrivez-vous" : "Connectez-vous"}
                        </SectionTitle>
                        <Box sx={{ m: 2 }} />
                        {view === "sub" && (
                            <SubForm 
                                setView={() => setView("notsub")}
                                offreId={offreId}
                                setErrorPriceId={setErrorPriceId}
                                priceId={priceId}
                            />
                        )}
                        {view === "notsub" && (
                            <NotSubForm 
                                setView={() => setView("sub")} 
                                offreId={offreId}
                                setErrorPriceId={setErrorPriceId}
                                priceId={priceId}
                            />
                        )}
                    </Fragment>
                ): null}
            </Box>
        </PageWrapper>
    )
}

export default SpecialSub