import { createSlice } from "@reduxjs/toolkit"
import { api } from "../../common/setupApi"

export const getDiscoverCompanyContent = () => async (dispatch, getState) => {
    const state = getState()
    const fetched = state.fetchedContent.discoverCompany.html !== ""

    if (fetched) {
        return
    }

    dispatch(fetchedContentActions.discoverCompanyGetLoading())

    return api
        .get("v1/core/block?slug=frontpage")
        .then(res => {
            dispatch(fetchedContentActions.discoverCompanyGetSucceeded(res.data.text))
        })
        .catch(err => {
            dispatch(fetchedContentActions.discoverCompanyGetFailed(err.response))
        })
}

// export const getDepartments = createAsyncThunk("fetchedContent/getDepartments", async (_, { rejectWithValue }) => {
//     return axios
//         .get("https://geo.api.gouv.fr/departements/")
//         .then(res => [{ code: "", nom: "", codeRegion: "", label: "" }].concat(res.data.map(d => ({ ...d, label: `${d.code} - ${d.nom}` }))))
//         .catch(err => rejectWithValue(err.response?.data))
// },{
//     condition: (_, { getState }) => {
//         // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
//         const { status } = selectDepartments(getState())
//         if (status === "fulfilled" || status === "pending") {
//             // Already fetched or in progress, don't need to re-fetch
//             return false
//         }
//     }
// })

const initialState = {
    discoverCompany: {
        html: "",
        loading: false,
        error: null
    },
    // departments: {
    //     status: "idle",
    //     data: [],
    //     error: null,
    // }
}

const fetchedContentSlice = createSlice({
    name: "fetchedContent",
    initialState,
    reducers: {
        discoverCompanyGetLoading: (state) => {
            state.discoverCompany.loading = true
            state.discoverCompany.error = null
        },
        discoverCompanyGetSucceeded: (state, action) => {
            state.discoverCompany.loading = false
            state.discoverCompany.html = action.payload
        },
        discoverCompanyGetFailed: (state, action) => {
            state.discoverCompany.loading = false
            state.discoverCompany.error = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            // .addCase(getDepartments.pending, (state) => {
            //     state.departments.status = "pending"
            //     state.departments.error = null
            // })
            // .addCase(getDepartments.fulfilled, (state, action) => {
            //     state.departments.status = "fulfilled"
            //     state.departments.data = action.payload
            // })
            // .addCase(getDepartments.rejected, (state, action) => {
            //     state.departments.status = "rejected"
            //     state.departments.error = action.payload
            // })
            .addCase("auth/logout", () => initialState)
    }
})

const fetchedContentActions = fetchedContentSlice.actions

// export const selectDepartments = (state) => {
//     const data = state.fetchedContent.departments.data
//     const status = state.fetchedContent.departments.status
//     const error = state.fetchedContent.departments.error
//     const isLoading = status === "pending"
//     return { data, status, error, isLoading } 
// }

export default fetchedContentSlice.reducer