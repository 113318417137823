import { Box } from "@mui/material";

const Link = ({ href }) => (
    <Box>
        <a 
            target="_blank" 
            rel="noreferrer nofollow" 
            href={href} 
            style={{
                textDecoration: "underline",
            }}
        >
            Ouvrir le document dans une nouvelle page
        </a> 
    </Box>
)

export default Link