import { Box, Grid, Typography } from "@mui/material"
import { scssVar } from "../../app/scssVar"
import DiscoverVideo from "../../common/components/DiscoverVideo"
import Logo from "../../common/components/Logo"
import { appName } from "../../common/utils"
import Button from "../../common/components/Button"

const SecondScreen = ({ onClick }) => {
    return (
        <Box sx={{ 
            p: { xs: 0, sm: "25px" },
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minHeight: "100%",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexGrow: 1,
                backgroundColor: scssVar.primary,
                borderRadius: { xs: 0, sm: "16px" },
            }}>
                <Grid 
                    container 
                    spacing={0} 
                    sx={{ 
                        flexGrow: 1,
                        width: "100%",
                        maxWidth: "1270px",
                        margin: "0 auto",
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%"
                        }}>
                            <Logo
                                variant="white"
                                sx={{
                                    wrapper: {
                                        pt: { xs: 5, md: 10 },
                                        pb: { xs: 2, md: 10 },
                                        width: 135
                                    },
                                    image: {
                                        borderRadius: "6px"
                                    }
                                }}
                            />
                            <Typography sx={{
                                fontFamily: scssVar.fontFamily,
                                color: "white",
                                fontSize: { xs: "35px", lg: "50px"},
                                lineHeight: "60px",
                                fontWeight: 700,
                                textAlign: "center",
                                pb: { xs: 2, md: 10 }
                            }}>
                                Avec {appName},
                                <br/>
                                <span style={{ color: scssVar.success }}>allons plus loin</span> 
                                <br/>
                                dans l’estimation
                                <br/>
                                immobilière !
                            </Typography>
                            <Box sx={{
                                pb: { xs: 5, md: 10 }
                            }}>
                                <Button 
                                    variant="success"
                                    onClick={onClick}
                                    sx={{ width: 150 }}
                                >
                                    Continuer
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            pb: { xs: 10, md: 0 }
                        }}>
                            <Box sx={{
                                width: { xs: "100%", sm: "80%"},
                                margin: "0 auto"
                            }}>
                                <DiscoverVideo />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default SecondScreen