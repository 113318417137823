import { Fragment, useState } from 'react';
import TabFicheBien from './ficheBien/TabFicheBien';
import TabSynthese from './synthese/TabSynthese';
import TabMap from './carte/TabMap';
import TabBudgetUsage from './budgetUsage/TabBudgetUsage';
import TabPhotos from './photos/TabPhotos';
import TabAds from './annonce/TabAds';
import TabMandat from './mandat/TabMandat';
import TabVisits from './visites/TabVisits';
import TabBuy from './achat/TabBuy';
import { a11yProps, Tab, TabPanel, Tabs, TabTitle } from '../../../../common/components/Tabs';
import { useSelector } from 'react-redux';
import { selectHousing } from '../../selectors';

const tabs = [
    { title: "Synthèse", desc: "Résumé du bien et de l’évaluation", component: TabSynthese },
    { title: "Fiche du bien", desc: "Éditez la partie souhaitée", component: TabFicheBien },
    { title: "Budget d’usage", desc: "Budget d’usage par mois ou année une fois le bien acquis", titleTheme: "#FADBB8", component: TabBudgetUsage },
    { title: "Photos", desc: "Ajoutez des photos du bien", component: TabPhotos },
    { title: "Carte", desc: "Localisez les commerces & transports à proximité", component: TabMap },
    { title: "Annonce", desc: "Générez votre annonce immobilière", component: TabAds },
    { title: "Mandat", desc: "Editez & générez votre mandat", component: TabMandat },
    { title: "Visites", desc: "Editez & générez vos documents de visites", component: TabVisits },
    { title: "Achat", desc: "Editez & générez vos documents d'achat", component: TabBuy },
]

const TabsNav = () => {
    const isHouse = useSelector(selectHousing).is_house
    const isFlat = useSelector(selectHousing).is_flat
    const [value, setValue] = useState(0)
    const flatOrHouseText = isHouse ? "de maisons" : isFlat ? "d'appartements" : "de biens"

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Fragment>
            <Tabs 
                value={value} 
                onChange={handleChange} 
                aria-label="eval tabs"
                variant="scrollable"
                // scrollButtons
                // allowScrollButtonsMobile
                // ScrollButtonComponent={(props) => {
                //     console.log(props)
                // }}
            >
                {tabs.map((d, i) => <Tab label={d.title} {...a11yProps(i)} key={i} />)}
            </Tabs>
            {tabs.map((d, i) => {
                const Component = d.component
                const desc = 
                    d.title === "Carte" 
                        ? `Localisez les ventes ${flatOrHouseText} similaires à proximité` 
                        : d.desc
                return (
                    <TabPanel value={value} index={i} key={i}>
                        <TabTitle title={d.title} subTitle={desc} bottomBarColor={d.titleTheme} />
                        <Component />
                    </TabPanel>
                )
            })}
        </Fragment>
    )
}

export default TabsNav