import { Box, Typography } from "@mui/material"
import { Fragment } from "react"
import { useSelector } from "react-redux"
import { scssVar } from "../../../app/scssVar"
import { appName, emailCompany, phoneWithSpaces, telCompany } from "../../../common/utils"

const Wrapper = ({ children }) => (
    <Fragment>
        <Box sx={{
            p: 2,
            bgcolor: scssVar.primary,
            position: "relative"
        }}>
            <Box sx={{ 
                position: "absolute", 
                top: -10, 
                left: -10, 
                bgcolor: scssVar.danger, 
                borderRadius: "50%" 
            }}>
                <i
                    className="iconoir-warning-circled-outline"
                    style={{
                        color: "white",
                        fontSize: 30
                    }}
                ></i>
            </Box>
            <Typography sx={{
                textAlign: "center",
                color: "white",
                fontFamily: scssVar.fontFamily,
            }}>
                {children}
            </Typography>
        </Box>
        <hr/>
    </Fragment>
)

const SubError = () => {
    const subError = useSelector(state => state.auth.user.subError)
    // const subError = "free_trial_ended"
    // const subError = "requires_payment_method"
    // const subError = "requires_action"

    if (subError === "free_trial_ended") {
        return (
            <Wrapper>
                Votre essai gratuit touche à sa fin, merci d'avoir testé {appName} et contribué à son amélioration !
                Pour poursuivre l'utilisation de notre outil et conserver vos données, 
                contactez Claude-Olivier Bonnet (CEO & Fondateur) 
                par mail 
                <a
                    href={`mailto:${emailCompany}`}
                    style={{ 
                        color: "white", 
                        whiteSpace: "nowrap",
                    }} 
                >
                    {" "}<u>{emailCompany}</u>{" "}
                </a>
                ou par téléphone au 
                <a 
                    href={`tel:${telCompany}`}
                    style={{ 
                        color: "white", 
                        whiteSpace: "nowrap",
                    }} 
                >
                    {" "}<u>{phoneWithSpaces(telCompany)}</u>
                </a>. 
            </Wrapper>
        )
    }

    if (subError === "requires_payment_method") {
        return (
            <Wrapper>
                Votre paiement a été refusé, pour rétablir votre abonnement, 
                rendez-vous dans
                <br/>
                "Abonnement" {">"} "Portail client"
            </Wrapper>
        )
    }

    if (subError === "requires_action") {
        return (
            <Wrapper>
                Votre paiement nécessite une autorisation auprès de votre banque. 
                rendez-vous dans
                <br/>
                "Abonnement" {">"} "Portail client"
            </Wrapper>
        )
    }

    return null
}

export default SubError