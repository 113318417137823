import axios from "axios"
import store from "../app/store"
import { logoutExpiredUserSession, refreshUserToken } from "../features/auth/actions"
import { selectAuth } from "../features/auth/selectors"

const hostname = window.location.hostname
console.log(hostname)
export const isProd = hostname === "estim.bonnissimmo.fr"
let isLocal = false

export const isDev = hostname.includes("192.168.0.")
    || hostname === "127.0.0.1" 
    || hostname === "localhost" 

// if (isProd) {
//     isLocal = false
// }

const APIS = {
    STAGING: "https://api-staging.bonnissimmo.fr/",
    PRODUCTION: "https://api.bonnissimmo.fr/",
    LOCAL: "http://192.168.0.19:8000/"
}

function getApi() {
    if (isProd) {
        return APIS.PRODUCTION
    }

    if (isLocal) {
        return APIS.LOCAL
    }

    return APIS.STAGING
}

export const backendUrl = `${getApi()}` 
export const baseURL = `${getApi()}api` 

export const api = axios.create({ 
    baseURL
})

const setupApi = () => {
    // Avant chaque envoi de requête, on ajoute le token access dans le header
    api.interceptors.request.use(
        config => {
            const accessToken = selectAuth(store.getState()).tokens.access

            if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

            return config
        },
        error => Promise.reject(error)
    )

    // Ici on regarde les tokens pour voir s'ils ont expirés ou pas
    api.interceptors.response.use(
        response => response,
        async error => {
            const unauthorized = error.response.status === 401
            const originalRequest = error.config
            // Mauvais identifiants renvoie une erreur 401
            const userIsTryingToLogin = originalRequest.url === "auth/token/"
            const refreshTokenHasExpired = originalRequest.url === "auth/token/refresh/"
            const userIsInApiIframe = originalRequest.url === "v1/api/iframe-config/"

            // Si unauthorized cela veut dire que un des 2 tokens a expiré
            if (unauthorized && !userIsTryingToLogin && !userIsInApiIframe) {

                // Si ce code d'erreur concerne l'endpoint de refresh, cela veut dire
                // que nous avons essayé de renouveller l'access, mais que le refresh a expiré, 
                // nous devons donc déconnecter l'user
                if (refreshTokenHasExpired) {
                    console.log("refresh token has expired")

                    //if (isDev) store.dispatch(addAlert("refresh token has expired", "error"))

                    // User est déjà logout, on doit donc supprimer les tokens
                    // On n'envoit pas de requête à v1/profile/logout/ car elle sera 401 (puisque les tokens ont expirés)
                    // sinon on aurait une boucle infinie
                    await store.dispatch(logoutExpiredUserSession())

                    return Promise.reject(error)
                } else {
                    // Si ce code ne concerne pas l'endpoint de refresh
                    // cela veut dire que l'access a expiré, et que le refresh n'a pas encore été évalué 
                    // On doit essayer de renouveler l'access token
                    // On envoie donc à api/token/refresh/ le refresh token
                    // Mais s'il a lui aussi expiré, cet interceptor déconnectera grâce au 
                    // block précédent car nous aurons une erreur 401

                    // on set retry à true pour éviter un cycle sans fin de réponses 401
                    // Puisque dans ce block, nous renvoyons une requête et non une erreur, 
                    // l'interceptor fera
                    // son job et renverra une erreur 401 s'il y en a une !

                    // Le but de ce block est de réessayer la requête échouée à cause de 
                    // l'expiration de l'access
                    if (!originalRequest._retry) {
                        originalRequest._retry = true
                        
                        console.log("try to refresh token")

                        //if (isDev) store.dispatch(addAlert("try to refresh token", "error"))

                        await store.dispatch(refreshUserToken())

                        return api(originalRequest)
                    }
                }
            }
            
            return Promise.reject(error)
        }
    )
}

export default setupApi