import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import NoMatch from '../common/components/NoMatch';
import ScrollToTop from '../common/components/ScrollToTop';
import useAuth from '../features/auth/hooks/useAuth';
import setupApi from '../common/setupApi';
import Account from '../features/account/Account';
import Alerts from '../features/alerts/Alerts';
import Synthesis from '../features/estimate/synthesis/Synthesis';
import Estimate from '../features/estimate/Estimate';
import Result from '../features/estimate/result/Result';
import ResultFinal from '../features/estimate/result/ResultFinal';
import Estimations from '../features/estimations/Estimations';
import { getUserInfos } from '../features/auth/actions';
import Login from '../features/auth/Login';
import RegisterOrganization from '../features/register/RegisterOrganization';
import RegisterUser from '../features/register/RegisterUser';
import UserOpinion from '../features/userOpinion/UserOpinion';
import UpdateListener from '../features/appUpdate/UpdateListener'
import LoadingScreen from '../common/components/LoadingScreen';
import Home from '../features/home/Home';
import CguChanged from '../features/account/CguChanged';
import InstallPrompt from '../features/appInstall/InstallPrompt';
import InstallIOS from '../features/appInstall/InstallIOS';
import History from '../features/history/History';
import NavTracker from '../features/userTracker/NavTracker';
import StepProvider from '../features/estimate/steps/StepProvider';
import { getOrgInfos, getTeams } from '../features/account/actions';
import FirstVisitScreens from '../features/firstVisit/FirstVisitScreens';
import Opt from '../features/opt/Opt';
import SpecialSub from '../features/register/specialSub/SpecialSub';
import SynthesisPdf from '../features/pdfCreator/synthesis/SynthesisPdf';
import ApiOffer from '../features/apiOffer/ApiOffer';
import ApiIframe from '../features/apiOffer/iframe/ApiIframe';
import { selectIsUpdateAvailable } from '../features/appUpdate/appUpdateSlice';
import './App.scss';
setupApi();

const Layout = () => (
    <Fragment>
        <Alerts />
        <UpdateListener />
        <InstallPrompt />
        <InstallIOS />
        <History />
        <NavTracker />
        <Outlet />
    </Fragment>
)

const Public = ({ children }) => (
    <Fragment>
        <ScrollToTop />
        {children}
    </Fragment>
)

const Private = ({ children }) => {
    const dispatch = useDispatch()
    const { isAuth, user } = useAuth()
    const location = useLocation()
    const isUpdateAvailable = useSelector(selectIsUpdateAvailable)

    useEffect(() => {
        if (isAuth) {
            dispatch(getUserInfos())
            dispatch(getOrgInfos())
            if (user.isAdmin) {
                dispatch(getTeams())
            }
        }
    }, [isAuth, user.isAdmin, dispatch])

    if (!isAuth) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    // Ecran de chargement si les user infos chargent
    // Permet de ne pas afficher la page d'accueil pendant 
    // que les tokens et firstVisit sont évalués
    if (user.loading) {
        return <LoadingScreen />
    }

    if (!user.cgu && !isUpdateAvailable) {
        return (
            <Fragment>
                <ScrollToTop />
                <CguChanged />
            </Fragment>
        )
    }

    // user.error.status !== 401
    if (user.subError || user.error) {
        return (
            <Fragment>
                <ScrollToTop />
                <UserOpinion />
                <Account />
            </Fragment>
        )
    }

    if (user.firstVisit) {
        return (
            <Fragment>
                <ScrollToTop />
                <FirstVisitScreens />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <ScrollToTop />
            {children}
        </Fragment>
    )
} 

const LoginPage = () => {
    const { isAuth } = useAuth()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"

    if (isAuth) {
        return <Navigate to={from} replace />
    }

    return (
        <Fragment>
            <ScrollToTop />
            <Login to={from} />
        </Fragment>
    )
}

const HomePage = () => (
    <Private>
        <UserOpinion />
        <Home />
    </Private>
)

const EstimatePage = () => (
    <Private>
        <UserOpinion />
        <Estimate />
    </Private>
)

const StepPage = () => (
    <Private>
        <UserOpinion />
        <StepProvider />
    </Private>
)

const ResultPage = () => (
    <Private>
        <UserOpinion />
        <Result />
    </Private>
)

const ResultFinalPage = () => (
    <Private>
        <UserOpinion />
        <ResultFinal />
    </Private>
)

const SynthesisPage = () => (
    <Private>
        <UserOpinion />
        <Synthesis />
    </Private>
)

const SynthesisPdfPage = () => (
    <Private>
        <SynthesisPdf />
    </Private>
)

const EstimationsPage = () => (
    <Private>
        <UserOpinion />
        <Estimations />
    </Private>
)

const ApiOfferPage = () => (
    <Private>
        <UserOpinion />
        <ApiOffer />
    </Private>
)

const ApiIframePage = () => (
    <Public>
        <ApiIframe />
    </Public>
)

const AccountPage = () => (
    <Private>
        <UserOpinion />
        <Account />
    </Private>
)

const RegisterOrganizationPage = () => (
    <Public>
        <RegisterOrganization />
    </Public>
)

const RegisterUserPage = () => (
    <Public>
        <RegisterUser />
    </Public>
)

const OptPage = () => (
    <Public>
        <Opt />
    </Public>
)

const SpecialSubPage = () => (
    <Public>
        <SpecialSub />
    </Public>
)

const NoMatchPage = () => (
    <Public>
        <NoMatch />
    </Public>
)

const App = () => (
    <Routes>
        <Route element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/estimer" element={<EstimatePage />} />
            <Route path="/estimer/step-:stepId" element={<StepPage />} />
            <Route path="/estimer/resultat" element={<ResultPage />} />
            <Route path="/estimer/resultat-final" element={<ResultFinalPage />} />
            <Route path="/estimer/synthese" element={<SynthesisPage />} />
            <Route path="/estimer/synthese-pdf" element={<SynthesisPdfPage />} />
            <Route path="/mes-evaluations" element={<EstimationsPage />} />
            <Route path="/offre-api" element={<ApiOfferPage />} />
            <Route path="/integration" element={<ApiIframePage />} />
            <Route path="/mon-compte" element={<AccountPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/inscription" element={<RegisterOrganizationPage />} />
            <Route path="/inscription/utilisateur" element={<RegisterUserPage />} />
            <Route path="/preferences" element={<OptPage />} />
            <Route path="/offres-speciales" element={<SpecialSubPage />} />
            <Route path="*" element={<NoMatchPage />} />
        </Route>
    </Routes>
)

export default App
