import { Box, Divider, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import image1 from "../../common/assets/image1.png"
import { backendUrl } from "../../common/setupApi"
import { loginAdminAsUser, loginUser } from "./actions"
import { useNavigate, useSearchParams } from "react-router-dom"
import Logo from "../../common/components/Logo"
import { scssVar } from "../../app/scssVar"
import FieldControl from "../../common/components/FieldControl"
import PageWrapper from "../../common/components/PageWrapper"
import Button from "../../common/components/Button"

const initialState = {
    passwordRevealed: false,
    email: "",
    password: ""
}

const Login = ({ to }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loading = useSelector(state => state.auth.loading)
    const [state, setState] = useState(initialState)
    const [searchParams] = useSearchParams()
    const sesame = searchParams.get("sesame")

    useEffect(() => {
        if (sesame !== null) {
            dispatch(loginAdminAsUser(sesame))
        }
    }, [dispatch, sesame])

    const handleChange = e => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }

    const togglePassword = () => {
        setState({ 
            ...state, 
            passwordRevealed: !state.passwordRevealed 
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        dispatch(loginUser(email, password, to))
    }

    const { passwordRevealed, email, password } = state
    const emptyEmail = !email.trim().length
    const emptyPwd = !password.trim().length

    return (
        <PageWrapper>
            <Box sx={{ display: "flex", pt: 5 }}>
                <Box sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    "@media (max-width: 599px)": {
                        width: "100%"
                    }
                }}>
                    <Box
                        component="form"
                        sx={{
                            width: "100%",
                            "@media (max-width: 599px)": {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column"
                            }
                        }}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Logo sx={{ wrapper: { mb: 4 } }} />
                        <Typography sx={{ 
                            fontSize: 36, 
                            fontWeight: 700, 
                            color: "black",
                            mb: 4,
                            fontFamily: scssVar.fontFamily
                        }}>
                            Se connecter
                        </Typography>
                        <Box sx={{ 
                            mb: 3,
                            width: "75%",
                            maxWidth: "400px",
                            "@media (max-width: 900px)": {
                                width: "100%"
                            } 
                        }}>
                            <FieldControl
                                required
                                label="Adresse email"
                                placeholder="Adresse email"
                                isSuccess={!emptyEmail}
                                value={email}
                                onChange={handleChange}
                                type="email"
                                name="email"
                                autoComplete="email"
                            />
                        </Box>
                        <Box sx={{ 
                            mb: 4,
                            width: "75%",
                            maxWidth: "400px",
                            "@media (max-width: 900px)": {
                                width: "100%"
                            } 
                        }}>
                            <FieldControl
                                required
                                label="Mot de passe"
                                placeholder="Mot de passe"
                                isSuccess={!emptyPwd}
                                value={password}
                                onChange={handleChange}
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                withVisibilityIcons
                                visible={passwordRevealed}
                                onToggleVisibility={togglePassword}
                            />
                        </Box>
                        <Box sx={{
                            mb: 2.75,
                        }}>
                            <Button 
                                loading={loading}
                                disabled={loading}
                                sx={{
                                    pl: 5,
                                    pr: 5
                                }}
                            >
                                Connexion
                            </Button>
                        </Box>
                        <Box sx={{
                            mb: 6,
                        }}>
                            <a 
                                href={`${backendUrl}accounts/password-reset/`}
                                rel="nofollow"
                                style={{
                                    fontSize: "0.8em",
                                    textDecoration: "underline"
                                }}
                                className="has-text-success"
                            >
                                J'ai oublié mon mot de passe
                            </a>
                        </Box>
                        <Divider sx={{ 
                            width: "75%",
                            mb: 2.5,
                            maxWidth: "400px",
                            "@media (max-width: 900px)": {
                                width: "100%"
                            } 
                        }} />
                        <Box sx={{
                            mb: 5
                        }}>
                            <Button
                                variant="primary-light"
                                onClick={() => navigate("/inscription")}
                                sx={{
                                    fontSize: 13,
                                    borderRadius: "9px",
                                    pl: 5,
                                    pr: 5,
                                    height: 35,
                                    bgcolor: scssVar.yellow,
                                    fontWeight: 600,
                                    letterSpacing: ".7px"
                                }}
                            >
                                Testez nous gratuitement
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    pt: 2,
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    "@media (max-width: 599px)": {
                        display: "none"
                    }
                }}>
                    <img 
                        src={image1} 
                        alt="interieur" 
                        style={{
                            width: "80%",
                            maxWidth: 455
                        }}
                    />
                </Box>
            </Box>
        </PageWrapper>
    )
}

export default Login