import { appName } from "../utils"
import IframeResponsive from "./IframeResponsive"

const DiscoverVideo = () => {
    return (
        <IframeResponsive
            ar="16:9"
            src=""
            title={`Découvrir ${appName}`}
        />
    )
}

export default DiscoverVideo